import { __rest } from "tslib";
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { distinctUntilKeyChanged, pluck, filter } from 'rxjs/operators';
import { locale } from 'svelte-i18n';
import { APP_INITIAL_STATE } from '../constants.js';
import { notNullish } from '../utils.js';
import { ADD_CHAINS, ADD_WALLET, UPDATE_WALLET, REMOVE_WALLET, RESET_STORE, UPDATE_ACCOUNT, UPDATE_CONNECT_MODAL, UPDATE_ACCOUNT_CENTER, UPDATE_NOTIFY, SET_WALLET_MODULES, SET_LOCALE, ADD_NOTIFICATION, REMOVE_NOTIFICATION, UPDATE_ALL_WALLETS, UPDATE_CHAINS, UPDATE_APP_METADATA, UPDATE_WAGMI_CONFIG } from './constants.js';
function reducer(state, action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_CHAINS:
            return Object.assign(Object.assign({}, state), { chains: [...state.chains, ...payload] });
        case UPDATE_CHAINS: {
            const updatedChain = payload;
            const chains = state.chains;
            const index = chains.findIndex((chain) => chain.id === updatedChain.id);
            chains[index] = updatedChain;
            return Object.assign(Object.assign({}, state), { chains });
        }
        case ADD_WALLET: {
            const wallet = payload;
            const existingWallet = state.wallets.find(({ label }) => label === wallet.label);
            return Object.assign(Object.assign({}, state), { wallets: [
                    // add to front of wallets as it is now the primary wallet
                    existingWallet || payload,
                    // filter out wallet if it already existed
                    ...state.wallets.filter(({ label }) => label !== wallet.label)
                ] });
        }
        case UPDATE_WALLET: {
            const update = payload;
            const { id } = update, walletUpdate = __rest(update, ["id"]);
            const updatedWallets = state.wallets.map(wallet => wallet.label === id ? Object.assign(Object.assign({}, wallet), walletUpdate) : wallet);
            return Object.assign(Object.assign({}, state), { wallets: updatedWallets });
        }
        case REMOVE_WALLET: {
            const update = payload;
            return Object.assign(Object.assign({}, state), { wallets: state.wallets.filter(({ label }) => label !== update.id) });
        }
        case UPDATE_ACCOUNT: {
            const update = payload;
            const { id, address } = update, accountUpdate = __rest(update, ["id", "address"]);
            const updatedWallets = state.wallets.map(wallet => {
                if (wallet.label === id) {
                    wallet.accounts = wallet.accounts.map(account => {
                        if (account.address === address) {
                            return Object.assign(Object.assign({}, account), accountUpdate);
                        }
                        return account;
                    });
                }
                return wallet;
            });
            return Object.assign(Object.assign({}, state), { wallets: updatedWallets });
        }
        case UPDATE_ALL_WALLETS: {
            const updatedWallets = payload;
            return Object.assign(Object.assign({}, state), { wallets: updatedWallets });
        }
        case UPDATE_CONNECT_MODAL: {
            const update = payload;
            return Object.assign(Object.assign({}, state), { connect: Object.assign(Object.assign({}, state.connect), update) });
        }
        case UPDATE_ACCOUNT_CENTER: {
            const update = payload;
            return Object.assign(Object.assign({}, state), { accountCenter: Object.assign(Object.assign({}, state.accountCenter), update) });
        }
        case UPDATE_NOTIFY: {
            const update = payload;
            return Object.assign(Object.assign({}, state), { notify: Object.assign(Object.assign({}, state.notify), update) });
        }
        case ADD_NOTIFICATION: {
            const update = payload;
            const notificationsUpdate = [...state.notifications];
            const notificationExistsIndex = notificationsUpdate.findIndex(({ id }) => id === update.id);
            if (notificationExistsIndex !== -1) {
                // if notification with same id, replace it with update
                notificationsUpdate[notificationExistsIndex] = update;
            }
            else {
                // otherwise add it to the beginning of array as new notification
                notificationsUpdate.unshift(update);
            }
            return Object.assign(Object.assign({}, state), { notifications: notificationsUpdate });
        }
        case REMOVE_NOTIFICATION: {
            const id = payload;
            return Object.assign(Object.assign({}, state), { notifications: state.notifications.filter(notification => notification.id !== id) });
        }
        case SET_WALLET_MODULES: {
            return Object.assign(Object.assign({}, state), { walletModules: payload });
        }
        case SET_LOCALE: {
            // Set the locale in the svelte-i18n internal state
            locale.set(payload);
            return Object.assign(Object.assign({}, state), { locale: payload });
        }
        case UPDATE_APP_METADATA: {
            const update = payload;
            return Object.assign(Object.assign({}, state), { appMetadata: Object.assign(Object.assign(Object.assign({}, state.appMetadata), update), { name: update.name || '' }) });
        }
        case UPDATE_WAGMI_CONFIG: {
            const update = payload;
            return Object.assign(Object.assign({}, state), { wagmiConfig: update });
        }
        case RESET_STORE:
            return APP_INITIAL_STATE;
        default:
            throw new Error(`Unknown type: ${type} in appStore reducer`);
    }
}
const _store = new BehaviorSubject(APP_INITIAL_STATE);
const _stateUpdates = new Subject();
_stateUpdates.subscribe(_store);
export function dispatch(action) {
    const state = _store.getValue();
    _stateUpdates.next(reducer(state, action));
}
function select(stateKey) {
    if (!stateKey)
        return _stateUpdates.asObservable();
    const validStateKeys = Object.keys(_store.getValue());
    if (!validStateKeys.includes(String(stateKey))) {
        throw new Error(`key: ${stateKey} does not exist on this store`);
    }
    return _stateUpdates
        .asObservable()
        .pipe(distinctUntilKeyChanged(stateKey), pluck(stateKey), filter(notNullish));
}
function get() {
    return _store.getValue();
}
export const state = {
    select,
    get
};
