export default `
<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 26.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Symbol" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 384 383" style="enable-background:new 0 0 384 383;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FCFF52;}
</style>
<path class="st0" d="M383.5,0H0.5v383h383V249.3h-63.6C298,298.1,248.7,332,192.3,332c-77.8,0-140.8-63.6-140.8-140.8
	C51.4,114,114.5,51,192.3,51c57.5,0,106.8,35,128.7,84.9h62.5V0z"/>
</svg>
`;
