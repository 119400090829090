import { get } from 'svelte/store';
import { _ } from 'svelte-i18n';
import defaultCopy from './i18n/en.json';
import { weiToEth } from '@web3-onboard/common';
import { validateTransactionHandlerReturn } from './validation.js';
import { state } from './store/index.js';
import { addNotification } from './store/actions.js';
import updateBalances from './update-balances.js';
import { updateTransaction } from './streams.js';
export function handleTransactionUpdates(transaction) {
    const customized = state.get().notify.transactionHandler(transaction);
    const invalid = validateTransactionHandlerReturn(customized);
    if (invalid) {
        throw invalid;
    }
    if (transaction.eventCode === 'txConfirmed') {
        const addresses = [
            transaction.watchedAddress,
            transaction.counterparty
        ].filter(Boolean);
        updateBalances(addresses);
    }
    const notification = transactionEventToNotification(transaction, customized);
    addNotification(notification);
    updateTransaction(transaction);
}
export function transactionEventToNotification(transaction, customization) {
    const { id, hash, startTime, eventCode, direction, counterparty, value, asset, network } = transaction;
    const type = eventToType(eventCode);
    const key = `${id || hash}-${(typeof customization === 'object' && customization.eventCode) || eventCode}`;
    const counterpartyShortened = counterparty &&
        counterparty.substring(0, 4) +
            '...' +
            counterparty.substring(counterparty.length - 4);
    const formattedValue = weiToEth(value);
    const formatterOptions = counterparty && value
        ? {
            messageId: `notify.watched['${eventCode}']`,
            values: {
                verb: eventCode === 'txConfirmed'
                    ? direction === 'incoming'
                        ? 'received'
                        : 'sent'
                    : direction === 'incoming'
                        ? 'receiving'
                        : 'sending',
                formattedValue,
                preposition: direction === 'incoming' ? 'from' : 'to',
                counterpartyShortened,
                asset
            }
        }
        : {
            messageId: `notify.transaction['${eventCode}']`,
            values: { formattedValue, asset }
        };
    const formatter = get(_);
    const notificationDefaultMessages = defaultCopy.notify;
    const typeKey = counterparty
        ? 'watched'
        : 'transaction';
    const notificationMessageType = notificationDefaultMessages[typeKey];
    const defaultMessage = notificationMessageType[eventCode];
    const message = formatter(formatterOptions.messageId, {
        values: formatterOptions.values,
        default: defaultMessage
    });
    let notification = {
        id: id || hash,
        type,
        key,
        network,
        startTime: startTime || Date.now(),
        eventCode,
        message,
        autoDismiss: typeToDismissTimeout((typeof customization === 'object' && customization.type) || type)
    };
    if (typeof customization === 'object') {
        notification = Object.assign(Object.assign({}, notification), customization);
    }
    return notification;
}
export function eventToType(eventCode) {
    switch (eventCode) {
        case 'txSent':
        case 'txPool':
            return 'pending';
        case 'txSpeedUp':
        case 'txCancel':
        case 'txRequest':
        case 'txRepeat':
        case 'txAwaitingApproval':
        case 'txConfirmReminder':
        case 'txStuck':
            return 'hint';
        case 'txError':
        case 'txSendFail':
        case 'txFailed':
        case 'txDropped':
        case 'nsfFail':
        case 'txUnderpriced':
            return 'error';
        case 'txConfirmed':
            return 'success';
        default:
            return 'hint';
    }
}
export function typeToDismissTimeout(type) {
    switch (type) {
        case 'success':
        case 'hint':
            return 4000;
        default:
            return 0;
    }
}
