export default `
  <svg height="100%" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7827 11.3172L15.5966 8.23933L20.4858 15.8545L20.4881 17.3159L20.4722 7.25942C20.4606 7.0136 20.3301 6.7887 20.1218 6.6561L11.3194 1.5928C11.1135 1.49162 10.8523 1.49269 10.6468 1.59594C10.6191 1.60989 10.593 1.62499 10.568 1.64147L10.5374 1.66079L1.99318 6.6121L1.95999 6.62712C1.91737 6.64674 1.8743 6.67165 1.83382 6.70063C1.67186 6.81683 1.56424 6.98861 1.52944 7.18131C1.52423 7.21052 1.52039 7.24026 1.51855 7.27023L1.53197 15.4653L6.08607 8.40666C6.65942 7.47067 7.90869 7.1692 9.06835 7.1856L10.4295 7.22155L2.40986 20.0827L3.3552 20.627L11.4709 7.23458L15.0581 7.22155L6.96327 20.9519L10.3366 22.8921L10.7396 23.1239C10.9101 23.1932 11.111 23.1967 11.283 23.1347L20.2091 17.9618L18.5026 18.9507L13.7827 11.3172ZM14.4747 21.2849L11.0677 15.9375L13.1474 12.4083L17.622 19.461L14.4747 21.2849Z" fill="#2D374B"/>
    <path d="M11.0684 15.9375L14.4754 21.2849L17.6228 19.4609L13.1482 12.4083L11.0684 15.9375Z" fill="#28A0F0"/>
    <path d="M20.4887 17.3159L20.4864 15.8545L15.5972 8.23932L13.7832 11.3172L18.5031 18.9507L20.2097 17.9618C20.3771 17.8259 20.4783 17.6264 20.489 17.4111L20.4887 17.3159Z" fill="#28A0F0"/>
    <path d="M7.71943e-05 18.694L2.41 20.0826L10.4296 7.22152L9.0685 7.18557C7.90883 7.16916 6.65964 7.47063 6.08621 8.40662L1.53211 15.4652L0 17.8193V18.694H7.71943e-05Z" fill="white"/>
    <path d="M15.0582 7.22156L11.4712 7.23459L3.35547 20.627L6.19211 22.2603L6.96354 20.9519L15.0582 7.22156Z" fill="white"/>
    <path d="M21.9999 7.20306C21.97 6.45287 21.5638 5.76608 20.9275 5.36626L12.0097 0.237888C11.3803 -0.079066 10.594 -0.0794494 9.96363 0.237658C9.88913 0.275218 1.2912 5.26171 1.2912 5.26171C1.17223 5.31874 1.05764 5.38673 0.949789 5.46384C0.381801 5.87094 0.0355663 6.50346 0 7.19846V17.8194L1.53211 15.4653L1.5187 7.27029C1.52054 7.24032 1.52429 7.21088 1.52958 7.18175C1.56415 6.9889 1.67185 6.81689 1.83397 6.70069C1.87444 6.67171 10.6192 1.60995 10.647 1.596C10.8526 1.49275 11.1137 1.49168 11.3195 1.59286L20.122 6.65616C20.3302 6.78876 20.4608 7.01366 20.4723 7.25948V17.4111C20.4617 17.6265 20.3766 17.8259 20.2092 17.9619L18.5026 18.9508L17.6221 19.461L14.4748 21.285L11.283 23.1347C11.1111 23.1968 10.9101 23.1933 10.7397 23.124L6.96334 20.952L6.19191 22.2603L9.58559 24.2142C9.6978 24.278 9.79784 24.3345 9.87985 24.3807C10.0069 24.452 10.0935 24.4996 10.1241 24.5144C10.3653 24.6315 10.7123 24.6997 11.025 24.6997C11.3118 24.6997 11.5913 24.647 11.8559 24.5434L21.1266 19.1745C21.6587 18.7623 21.9717 18.1406 21.9999 17.467V7.20306Z" fill="#96BEDC"/>
  </svg>
`;
