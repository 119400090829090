import { __rest } from "tslib";
import { nanoid } from 'nanoid';
import { dispatch } from './index.js';
import { configuration } from '../configuration.js';
import { handleThemeChange, returnTheme } from '../themes.js';
import { state } from '../store/index.js';
import { validateAccountCenterUpdate, validateLocale, validateNotification, validateCustomNotification, validateCustomNotificationUpdate, validateString, validateWallet, validateWalletInit, validateUpdateBalances, validateNotify, validateConnectModalUpdate, validateUpdateTheme, validateSetChainOptions, validateAppMetadataUpdate } from '../validation.js';
import { ADD_CHAINS, UPDATE_WALLET, RESET_STORE, ADD_WALLET, REMOVE_WALLET, UPDATE_ACCOUNT, UPDATE_ACCOUNT_CENTER, UPDATE_NOTIFY, SET_WALLET_MODULES, SET_LOCALE, ADD_NOTIFICATION, REMOVE_NOTIFICATION, UPDATE_ALL_WALLETS, UPDATE_CONNECT_MODAL, UPDATE_CHAINS, UPDATE_APP_METADATA, UPDATE_WAGMI_CONFIG } from './constants.js';
export function addChains(chains) {
    // chains are validated on init
    const action = {
        type: ADD_CHAINS,
        payload: chains.map((_a) => {
            var { namespace = 'evm', id, rpcUrl } = _a, rest = __rest(_a, ["namespace", "id", "rpcUrl"]);
            return (Object.assign(Object.assign({}, rest), { namespace, id: id.toLowerCase(), rpcUrl: rpcUrl ? rpcUrl.trim() : null }));
        })
    };
    dispatch(action);
}
export function updateChain(updatedChain) {
    const { label, token, rpcUrl, id: chainId, namespace: chainNamespace } = updatedChain;
    const error = validateSetChainOptions({
        label,
        token,
        rpcUrl,
        chainId,
        chainNamespace
    });
    if (error) {
        throw error;
    }
    const action = {
        type: UPDATE_CHAINS,
        payload: updatedChain
    };
    dispatch(action);
}
export function addWallet(wallet) {
    const error = validateWallet(wallet);
    if (error) {
        console.error(error);
        throw error;
    }
    const action = {
        type: ADD_WALLET,
        payload: wallet
    };
    dispatch(action);
}
export function updateWallet(id, update) {
    const error = validateWallet(update);
    if (error) {
        console.error(error);
        throw error;
    }
    const action = {
        type: UPDATE_WALLET,
        payload: Object.assign({ id }, update)
    };
    dispatch(action);
}
export function removeWallet(id) {
    const error = validateString(id, 'wallet id');
    if (error) {
        throw error;
    }
    const action = {
        type: REMOVE_WALLET,
        payload: {
            id
        }
    };
    dispatch(action);
}
export function setPrimaryWallet(wallet, address) {
    const error = validateWallet(wallet) || (address && validateString(address, 'address'));
    if (error) {
        throw error;
    }
    // if also setting the primary account
    if (address) {
        const account = wallet.accounts.find(ac => ac.address === address);
        if (account) {
            wallet.accounts = [
                account,
                ...wallet.accounts.filter(({ address }) => address !== account.address)
            ];
        }
    }
    // Update wagmi config if wagmi is being used
    handleWagmiConnectorUpdate(wallet);
    // add wallet will set it to first wallet since it already exists
    addWallet(wallet);
}
export function updateAccount(id, address, update) {
    const action = {
        type: UPDATE_ACCOUNT,
        payload: Object.assign({ id,
            address }, update)
    };
    dispatch(action);
}
export function updateAccountCenter(update) {
    const error = validateAccountCenterUpdate(update);
    if (error) {
        throw error;
    }
    const action = {
        type: UPDATE_ACCOUNT_CENTER,
        payload: update
    };
    dispatch(action);
}
export function updateConnectModal(update) {
    const error = validateConnectModalUpdate(update);
    if (error) {
        throw error;
    }
    const action = {
        type: UPDATE_CONNECT_MODAL,
        payload: update
    };
    dispatch(action);
}
export function updateNotify(update) {
    const error = validateNotify(update);
    if (error) {
        throw error;
    }
    const action = {
        type: UPDATE_NOTIFY,
        payload: update
    };
    dispatch(action);
}
export function addNotification(notification) {
    const error = validateNotification(notification);
    if (error) {
        throw error;
    }
    const action = {
        type: ADD_NOTIFICATION,
        payload: notification
    };
    dispatch(action);
}
export function addCustomNotification(notification) {
    const customNotificationError = validateCustomNotificationUpdate(notification);
    if (customNotificationError) {
        throw customNotificationError;
    }
    const action = {
        type: ADD_NOTIFICATION,
        payload: notification
    };
    dispatch(action);
}
export function customNotification(updatedNotification) {
    const customNotificationError = validateCustomNotification(updatedNotification);
    if (customNotificationError) {
        throw customNotificationError;
    }
    const customIdKey = `customNotification-${nanoid()}`;
    const notification = Object.assign(Object.assign({}, updatedNotification), { id: customIdKey, key: customIdKey });
    addCustomNotification(notification);
    const dismiss = () => {
        if (notification.id) {
            removeNotification(notification.id);
        }
    };
    const update = (notificationUpdate) => {
        const customNotificationError = validateCustomNotification(updatedNotification);
        if (customNotificationError) {
            throw customNotificationError;
        }
        const notificationAfterUpdate = Object.assign(Object.assign({}, notificationUpdate), { id: notification.id, key: notification.key });
        addCustomNotification(notificationAfterUpdate);
        return {
            dismiss,
            update
        };
    };
    addCustomNotification(notification);
    return {
        dismiss,
        update
    };
}
export function removeNotification(id) {
    if (typeof id !== 'string') {
        throw new Error('Notification id must be of type string');
    }
    const action = {
        type: REMOVE_NOTIFICATION,
        payload: id
    };
    dispatch(action);
}
export function resetStore() {
    const action = {
        type: RESET_STORE
    };
    dispatch(action);
}
export function setWalletModules(wallets) {
    const error = validateWalletInit(wallets);
    if (error) {
        throw error;
    }
    const modules = initializeWalletModules(wallets);
    const dedupedWallets = uniqueWalletsByLabel(modules);
    const action = {
        type: SET_WALLET_MODULES,
        payload: dedupedWallets
    };
    dispatch(action);
}
export function setLocale(locale) {
    const error = validateLocale(locale);
    if (error) {
        throw error;
    }
    const action = {
        type: SET_LOCALE,
        payload: locale
    };
    dispatch(action);
}
export function updateAllWallets(wallets) {
    const error = validateUpdateBalances(wallets);
    if (error) {
        throw error;
    }
    const action = {
        type: UPDATE_ALL_WALLETS,
        payload: wallets
    };
    dispatch(action);
}
// ==== HELPERS ==== //
export function initializeWalletModules(modules) {
    const { device } = configuration;
    if (!device)
        return [];
    return modules.reduce((acc, walletInit) => {
        const initialized = walletInit({ device });
        if (initialized) {
            // injected wallets is an array of wallets
            acc.push(...(Array.isArray(initialized) ? initialized : [initialized]));
        }
        return acc;
    }, []);
}
export function uniqueWalletsByLabel(walletModuleList) {
    return walletModuleList.filter((wallet, i) => wallet &&
        walletModuleList.findIndex((innerWallet) => innerWallet && innerWallet.label === wallet.label) === i);
}
export function updateTheme(theme) {
    const error = validateUpdateTheme(theme);
    if (error) {
        throw error;
    }
    const themingObj = returnTheme(theme);
    themingObj && handleThemeChange(themingObj);
}
export function updateAppMetadata(update) {
    const error = validateAppMetadataUpdate(update);
    if (error) {
        throw error;
    }
    const action = {
        type: UPDATE_APP_METADATA,
        payload: update
    };
    dispatch(action);
}
export function updateWagmiConfig(update) {
    const action = {
        type: UPDATE_WAGMI_CONFIG,
        payload: update
    };
    dispatch(action);
}
function handleWagmiConnectorUpdate(wallet) {
    const { wagmi } = configuration;
    if (!wagmi)
        return;
    try {
        const { label } = wallet;
        const { wagmiConnect, getWagmiConnector } = wagmi;
        const wagmiConfig = state.get().wagmiConfig;
        const wagmiConnector = getWagmiConnector(label);
        wagmiConnect(wagmiConfig, { connector: wagmiConnector }).then(() => {
            updateWallet(label, { wagmiConnector });
        });
    }
    catch (e) {
        console.error(`Error updating Wagmi connector on primary wallet switch ${e}`);
    }
}
