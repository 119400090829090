import { configuration } from './configuration.js';
import { handleTransactionUpdates } from './notify.js';
let blocknativeMultiChainSdk;
let blocknativeSdk;
/**
 *
 * @returns MultiChain SDK if apiKey
 */
export async function getBNMulitChainSdk() {
    var _a;
    const { apiKey } = configuration;
    if (!apiKey)
        return null;
    if (!blocknativeMultiChainSdk) {
        const { default: Blocknative } = await import('bnc-sdk');
        blocknativeMultiChainSdk = Blocknative.multichain({
            apiKey: (_a = configuration.apiKey) !== null && _a !== void 0 ? _a : ''
        });
        blocknativeMultiChainSdk.transactions$.subscribe(handleTransactionUpdates);
    }
    return blocknativeMultiChainSdk;
}
/**
 *
 * @returns SDK if apiKey
 */
export async function getBlocknativeSdk() {
    var _a;
    const { apiKey } = configuration;
    if (!apiKey)
        return null;
    if (!blocknativeSdk) {
        const { default: Blocknative } = await import('bnc-sdk');
        blocknativeSdk = new Blocknative({
            dappId: (_a = configuration.apiKey) !== null && _a !== void 0 ? _a : '',
            networkId: 1
        });
        return blocknativeSdk;
    }
    return blocknativeSdk;
}
