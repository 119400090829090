export const ADD_CHAINS = 'add_chains';
export const UPDATE_CHAINS = 'update_chains';
export const RESET_STORE = 'reset_store';
export const ADD_WALLET = 'add_wallet';
export const UPDATE_WALLET = 'update_wallet';
export const REMOVE_WALLET = 'remove_wallet';
export const UPDATE_ACCOUNT = 'update_account';
export const UPDATE_ACCOUNT_CENTER = 'update_account_center';
export const UPDATE_CONNECT_MODAL = 'update_connect_modal';
export const SET_WALLET_MODULES = 'set_wallet_modules';
export const SET_LOCALE = 'set_locale';
export const UPDATE_NOTIFY = 'update_notify';
export const ADD_NOTIFICATION = 'add_notification';
export const REMOVE_NOTIFICATION = 'remove_notification';
export const UPDATE_ALL_WALLETS = 'update_balance';
export const UPDATE_APP_METADATA = 'update_app_metadata';
export const UPDATE_WAGMI_CONFIG = 'update_wagmi_config';
