export default `
<?xml version="1.0" encoding="UTF-8"?>
<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 477 477">
  <defs>
    <style>.cls-1{fill:#a36dfe;}.cls-2{fill:#4b2895;}</style>
  </defs>
  <circle class="cls-1" cx="238.5" cy="238.5" r="238.5"/>
  <g>
    <path class="cls-2" d="M237.85,113.99c29.25,1.32,54.97,2.45,80.69,3.69,3.95,.19,7.92,.72,11.82,1.43,9.23,1.69,15.34,8.72,14.78,18.2-1.33,22.33-3.22,44.63-4.79,66.95-.81,11.47-1.49,22.96-2.12,34.44-.29,5.24-2.03,8.14-7.74,10.18-37.84,13.52-76.98,15.46-116.47,13.42-23.24-1.2-45.99-5.86-68.06-13.68-4.71-1.67-6.72-3.91-7.03-8.72-1.31-20.46-2.83-40.9-4.25-61.35-.94-13.55-2.1-27.1-2.7-40.66-.49-11.15,6.17-18.37,17.2-19.01,30.7-1.78,61.4-3.39,88.69-4.88Z"/><path class="cls-2" d="M238.34,363.01c-31.19-1.24-61.52-5.51-90.12-18.31-20.8-9.31-38.13-23.01-48.65-43.63-3.63-7.1-5.68-15.3-6.91-23.24-1.17-7.59,1.95-14.47,8.76-18.93,6.74-4.42,13.4-3.5,20.12,.38,14.24,8.24,14.6,8.18,14.39,24.44-.09,7.1,3.71,11.23,8.41,15.24,12.51,10.67,26.94,17.78,42.7,21.94,38.88,10.26,77.66,10.06,115.42-4.42,11.31-4.33,21.33-12.17,31.67-18.82,5.27-3.39,7.7-8.7,6.88-15.19-1.34-10.7,2.19-17.99,12.86-22.48,7.8-3.29,14.95-6.25,22.38-.72,7.42,5.53,9.4,13.33,7.88,22.37-4.05,24.14-19.06,40.5-38.25,53.68-18.5,12.7-39.59,19.06-61.42,22.61-15.24,2.48-30.72,3.44-46.1,5.09Z"/>
  </g>
</svg>
`;
